<template>
  <div id="app">
    <br><br><br><br>
    <my-project/>
    <a id='beian' href='https://beian.miit.gov.cn/'>京ICP备2021029079号-1</a>
  </div>
</template>

<script>
import MyProject from './components/MyProject.vue'

export default {
  name: 'App',
  components: {
    MyProject
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

#beian{
  position: absolute;
  bottom: 0;
  font-size: 1px;
  color: rgba(0, 0, 0, 0.356);
}

</style>
